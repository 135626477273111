@import '../../../static/styles/variables';
@import '../../../static/styles/mediaqueries';

.title {
  font-family: $baderCrompress;
  font-size: 56px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: -25px;
  color: #2F1E00;

  @include media(m-device) {
    font-size: 42px;
    margin-bottom: 16px;
  }

  span {
    font-family: $sensa;
    color: $primary;
    text-transform: none;
    font-size: 72px;
    position: relative;
    top: -33px;
    display: block;

    @include media(m-device) {
      font-size: 54px;
      top: -5px;
      line-height: 36px; // TODO: recheck this
      margin-bottom: 0; 
    }
  }
}
