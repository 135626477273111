@import '../../static//styles/variables';
@import '../../static/styles/buttons';
@import '../../static/styles/mediaqueries';

.home {
    &__gifts {
        height: 620px;
        width: 100%;
        position: relative;
        z-index: 0;
        margin: 2rem 0;
        margin-bottom: 140px;

        @include media(m-device) {
            height: 100%;
            margin-bottom: 12rem;
        }

        &__content {
            width: 544px;
            position: absolute;
            left: 10rem;
            top: 23rem;
            padding: 24px 64px 40px 40px;
            z-index: 10;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: flex-start;
            background: $white;
            border-radius: 6px;
            box-shadow: 0px 4px 40px 0px #00000014;



            &__title {
                text-align: left;
                 @include media(m-device) {
                    width: 170px;
                    align-self: flex-start;
                 }
            }

            @include media(m-device) {
                width: 90%;
                max-width: 330px;
                position: relative;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                left: 0;
                top: 15rem;
                padding-left: 24px;
                padding-right: 24px;
                padding-top: 24px;
                padding-bottom: 40px;

            }

            p {
                font-family: $baderNarrow;
                color: $light-grey;
                font-size: 18px;
                line-height: 28px;
            }

            .btn__light {
                @extend .btn__light;
                margin-top: 22px;
                padding: 0 32px;

                span {
                    margin-top: -2px;
                }
            }
        }
    }

    &__grid {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        &__overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
        }
    }
    
}