@import "../../../static/styles/variables";
@import "../../../static/styles/buttons";
@import "../../../static/styles/mediaqueries";

.wrapper {
  overflow: hidden;
  width: 100%;

  &__text {
    display: flex;
    justify-content: space-between;

    @include media(m-device) {
      margin: 16px 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &__controls {
      display: flex;
      @include media(m-device) {
        margin: 1rem 0 0;
      }
    }
  }
}

.heading {
  font-size: 28px !important;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  font-family: $baderCrompress;
  color: #2F1E00;

  @include media(m-device) {
    font-size: 30px !important;
    line-height: 1.3;
    margin-bottom: 8px;
  }
}

.subtitle {
  text-align: left;
  font-family: $baderNarrow;
  color: $light-grey;
  font-size: 20px;
  line-height: 1.5em;
}

.container {
  display: flex;
  gap: 2rem;
  width: fit-content;
  margin: 0 auto;
  padding: 0 auto;
  margin-top: 2rem;
  transition: transform 0.3s ease-in-out;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40px;
    pointer-events: none;
    z-index: 1;
  }

  &::before {
    left: 0;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
    filter: blur(20px);
  }

  &::after {
    right: 0;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
    filter: blur(20px);
  }
}

.btn_container {
  display: flex;
  justify-content: center;
  

  .btn_light {
    @extend .btn__light;
    height: 50px;
  }
}

.SwiperWrapper {
  padding: 32px 0;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  box-sizing: content-box;
  @include media(m-device) {
    padding: 8px 0;
  }
  &__Overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 96px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    z-index: 999;
  }
}
