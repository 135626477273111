@import "../../../static/styles/variables";
@import "../../../static/styles/variables";

.card {
    height: 270px;
    width: 100%;
    border: 1px solid $primary;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1.5em;
    border-radius: 6px;
    box-shadow: 0px 4px 40px 0px #00000014;
    box-sizing: border-box;

    a {
        text-decoration: underline;
        color: $black;
        font-family: $baderNarrowBold;
    }
}

.title {
    font-family: $baderNarrow;
    color: $light-grey;
    text-align: left;
    line-height: 24px;
    font-size: 16px;
}