@import "../../static/styles/variables";
@import "../../static/styles/mediaqueries";
@import "../../static/styles/buttons";


.timeline {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  max-width: 1088px;
  margin: auto;
  padding-bottom: 80px;

  @include media(m-device) {
    flex-direction: column;
    padding: 20px 0;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 92px;
    width: 100%;
    height: 1px;
    background-color: $very-light-pink;
    z-index: 0;

    @include media(m-device) {
      content: unset;
    }
  }

  &__card {
    width: 20%;
    position: relative;
    z-index: 2;

    @include media(m-device) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &:last-child {
        span:first-of-type::after {
          content: unset;
        }
      }

      span {
        &:last-of-type {
          width: calc(100% - 60px);
        }

        &:first-of-type {
          height: 100%;
          width: 60px;
          display: flex;
          justify-content: center;
          align-items: flex-start;

          position: relative;

          &::after {
            content: "";
            width: 1px;
            height: 60px;
            top: 30px;
            position: absolute;
            background-color: $very-light-pink;
            z-index: 0;
            left: 50%;
          }
        }
      }
    }

    h3 {
      font-family: $baderCrompress;
      font-size: 28px;
      text-align: center;
      position: relative;
      background-color: $white;
      width: 60px;
      font-weight: bold;
      color: $primary;
      padding: 5px 0;
      @include media(l-device) {
        padding: 75px 15px 32px;
        margin: auto;
      }

      @include media(m-device) {
        height: 100%;
        margin-top: 0 !important;
      }
    }

    h2 {
      font-family: $baderCrompress;
      font-size: 28px;
      text-transform: uppercase;
      color: $black;
      text-align: center;
      padding-bottom: 12px;
      @include media(m-device) {
        text-align: left;
      }
    }

    p {
      font-family: $baderNarrow;
      font-size: 1rem;
      line-height: 20px;
      color: $p-color;
      text-align: center;
      max-width: 170px;
      margin: auto;
      @include media(m-device) {
        text-align: left;
        margin: 0;
        padding-bottom: 20px;
        max-width: 230px;
      }
    }

    /*
    &:first-of-type {
      &::before {
        content: "";
        position: absolute;
        left: 0;
        height: 4px;
        top: 92px;
        background-color: $white;
        width: 40%;
      }
    }

    &:last-of-type {
      &::after {
        content: "";
        position: absolute;
        right: 0;
        height: 4px;
        top: 92px;
        background-color: $white;
        width: 40%;
      }
    }
    */
  }

  &__image {
    width: 100%;
    height: 220px;
    margin-bottom: 30px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include media(m-device) {
      width: 110px;
      height: 110px;
    }
  }
}

.btn__create {
  @extend .btn__primary;
  margin: 0 auto;
}
